import React, { useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { Link, withPrefix } from "gatsby"
import {
  InformationCircleIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/react/solid"

import PageContext from "../context/PageContext"
import { Keys } from "../components/icons/Keys"
import { ColorButton } from "../components/layout/ColorButton"
import { Katalog } from "../components/icons/Katalog"
import EstateAgent from "../icons/estateAgent.svg"
import { Step } from "../components/layout/Step"
import { Estates } from "../components/estate/Estates"
import { EstateDetail } from "../components/estate/EstateDetail"
import { Router } from "@reach/router"

const RealityPage = props => {
  useEffect(() => {
    window.emAppIsReady = true
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Realitní služby | Holding Malgen</title>
      </Helmet>
      <main className="pb-8">
        {/* Hero card */}
        <div className="relative h-96">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-200" />
          <StaticImage
            src="../images/dvere.jpg"
            className="h-full w-full absolute object-cover border border-gray-200 "
            alt="Malgen"
          />
          <div className="absolute bottom-0  sm:overflow-hidden w-full border-t border-gray-400">
            <div className="absolute inset-0 bottom-0">
              <div className="absolute inset-0 bg-gray-300 mix-blend-multiply backdrop-filter backdrop-blur-sm" />
            </div>
            <div className="relative px-4 max-w-7xl mx-auto">
              <h1 className="text-center  text-2xl lg:text-3xl grid py-6 px-3 ">
                <span className=" text-white">
                  Otevřeme vám dveře nového domova
                </span>
                <span className=" text-white text-2xl"></span>
              </h1>
            </div>
          </div>
        </div>
        <div className="  pt-12 sm:pt-16">
          <div className="py-10  bg-white  relative  ">
            <div aria-hidden="true" className="block absolute inset-y-0 w-full">
              <div className="absolute inset-y-0 right-10 lg:right-20 2xl:right-60 w-full bg-gray-100 rounded-r-3xl " />
            </div>

            <div className="relative">
              <div className="relative max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-6xl mx-auto ">
                  <div className=" grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 lg:justify-items-center relative">
                    <Keys className="w-20 h-20 absolute right-4 text-gray-400 -top-12 -mt-1 z-10" />
                    <div className="space-y-12 mx-10 mb-4 lg:mb-0 lg:w-44 space-x-2 lg:space-x-0 lg:mx-auto overflow-hidden">
                      <div>
                        <div className="lg:space-y-4 flex md:grid">
                          <div className="md:hidden overflow-hidden  w-24 h-42 flex rounded-lg">
                            <StaticImage
                              src="../images/kouril.jpg"
                              layout="fixed"
                              height={150}
                              className="  object-cover "
                              alt="Vladimír Kouřil"
                            />
                          </div>
                          <div className="md:flex overflow-hidden w-44 h-56 hidden rounded-lg">
                            <StaticImage
                              src="../images/kouril.jpg"
                              layout="fixed"
                              height={250}
                              className="  object-cover "
                              alt="Vladimír Kouřil"
                            />
                          </div>

                          <div className="space-y-2">
                            <div className="text-lg leading-6 p-6 md:px-0 lg:p-0 font-medium space-y-2">
                              <div className=" items-center text-base ">
                                <h3 className="text-xl text-blue-600 font-semibold">
                                  Vladimír Vach
                                </h3>
                                <h2 className="text-xs text-gray-400 font-semibold tracking-wide uppercase">
                                  realitní makléř
                                </h2>
                              </div>
                              <div className=" flex items-center text-base ">
                                <PhoneIcon
                                  className="h-4 w-4 text-gray-400 mr-3"
                                  aria-hidden="true"
                                />
                                <span>774 729 538</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-2 lg:col-span-3 px-5 max-w-xl lg:max-w-3xl py-6 lg:py-0 lg:pr-20 ">
                      <div className="px-6 lg:px-0 py-2">
                        <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
                          Špičkoví makléři k vašim službám
                        </h2>
                        <p className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                          Hledáte nemovitost svých snů?
                        </p>
                        <p className="mt-4 text-lg text-gray-500 lg:mx-auto">
                          Náš tým certifikovaných, mladých a flexibilních
                          makléřů se zkušenostmi z renomovaných realitních
                          kanceláří Vám nabízí své služby při prodeji, koupi či
                          pronájmu Vaší nemovitosti.
                        </p>
                        <p className="mt-4 text-lg text-gray-500 lg:mx-auto">
                          Zakládáme si na srozumitelném a jasném postupu, aby
                          nedošlo k nepříjemným překvapením. Bez stresu,
                          nečekaných komplikací a dlouhého čekání.
                        </p>
                        <div className="grid md:flex space-y-4 lg:space-y-0 lg:space-x-4 mt-8">
                          <Link to={"/katalog-nemovitosti/"}>
                            <ColorButton
                              icon={<Katalog />}
                              text={"Katalog nemovitostí"}
                            />
                          </Link>
                          <Link to={"/nas-tym/"}>
                            <ColorButton
                              icon={<EstateAgent />}
                              text={"Náš tým"}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-10 max-w-3xl mx-auto  relative px-8 ">
            <div className="my-5 pb-5 text-2xl leading-8  tracking-tight text-gray-900 sm:text-3xl text-center">
              Šetříme váš čas jasným a rychlým postupem
            </div>
            <Step
              step={"1"}
              even={false}
              text={"Nezávazná schůzka a prohlídka nemovitosti"}
            />
            <Step
              step={"2"}
              even={true}
              text={"Příprava nemovitosti k prodeji"}
            />
            <Step
              step={"3"}
              even={false}
              text={"Spuštění inzerce, následují prohlídky"}
            />
            <Step
              step={"4"}
              even={true}
              text={"Příprava kupní smlouvy a všech náležitostí s kupujícím"}
            />
            <Step
              step={"5"}
              even={false}
              text={"Kontrola zápisu v katastru, vyplacení peněz"}
            />

            <div className="flex w-full justify-end">
              <Link to={"/jak-pracujeme/"}>
                <ColorButton
                  icon={<InformationCircleIcon />}
                  text={"Jak pracujeme"}
                />
              </Link>
            </div>
          </div>

          <div className="py-10  max-w-6xl mx-auto  relative px-8 ">
            <div className="my-5 pb-5 text-2xl leading-8  tracking-tight text-gray-900 sm:text-3xl text-center">
              Aktuální nabídka nemovitostí
            </div>
            <Estates />
            <div className="flex w-full justify-end">
              <Link to={"/katalog-nemovitosti/"}>
                <ColorButton icon={<Katalog />} text={"Katalog nemovitostí"} />
              </Link>
            </div>
          </div>

          <div className="py-10  max-w-6xl mx-auto  relative px-8 ">
            <div id="cenovaMapaWidget"></div>
          </div>

          {/* <div dangerouslySetInnerHTML={{__html: '<script type="text/javascript">
  cenovamapaWG.init({
     widgetMode: 1,
     clientKey: ' XyXyXyXyXyXyXy',
     elementID: 'cenovaMapaWidget',
     widgetEmail: ['email5@seznam.cz', '5678@wg.cz'],
     htmlTerms: 'Odesláním formuláře prohlašuji, že vyplněné údaje se týkají mé osoby, seznámil jsem se ' +
          's informacemi o <a href="-link-" target="_blank"><strong<Ochraně osobních údajů</strong></a>'+
          'a souhlasím se zpracováním mých osobních údajů za účelem vyhotovení tržního ocenění zadané nemovitosti' +
          ' případně následného kontaktu.<br /><br />' +
          'Souhlas uděluji společnosti -název společnosti-, IČO: -IČO- a společnosti OctopusPro, ' +
          's.r.o., IČ: 28339215. Souhlas se zasíláním nabídek lze kdykoli odvolat pomocí odkazu ' +
          'uvedeného na e-mailové adrese společnosti.'
  });
</script>'}}></div> */}
          {/* {
        cenovamapaWG.init({
          widgetMode: 1,
          clientKey: 'M3u%2fRJQo0%2fk%3d',
          elementID: 'cenovaMapaWidget',
          widgetEmail: ['reality@malgen.cz'],
          htmlTerms: 'Odesláním formuláře prohlašuji, že vyplněné údaje se týkají mé osoby, seznámil jsem se ' +
                's informacemi o <a href="/gdpr" target="_blank"><strong<Ochraně osobních údajů</strong></a>'+
                'a souhlasím se zpracováním mých osobních údajů za účelem vyhotovení tržního ocenění zadané nemovitosti' +
                ' případně následného kontaktu.<br /><br />' +
                'Souhlas uděluji společnosti MGS HOME s.r.o., IČO: 01698320 a společnosti OctopusPro, ' +
                's.r.o., IČ: 28339215. Souhlas se zasíláním nabídek lze kdykoli odvolat pomocí odkazu ' +
                'uvedeného na e-mailové adrese společnosti.'
        })
      } */}

          <div className="relative max-w-xl mx-auto my-5">
            <div
              className="absolute px-5 inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-2 text-gray-500">
                <svg
                  version="1.1"
                  id="Layer_1"
                  fill="currentColor"
                  className="w-10"
                  viewBox="0 0 114.563 60.68"
                >
                  <path
                    d="M30.665,58.295c7.085,0.098,13.808-2.571,18.891-7.516c1.815-1.766,3.334-3.776,4.565-5.921
                            c0.308,0.154,0.626,0.278,0.987,0.283l16.678,0.462c0.459,0.006,0.901-0.128,1.296-0.372l4.423-2.841l6.384,2.95
                            c0.707,0.321,1.518,0.262,2.164-0.13l6.068-3.828l7.859,4.142c0.865,0.442,1.929,0.297,2.628-0.384l8.207-7.983
                            c0.903-0.878,0.913-2.31,0.044-3.203l-7.983-8.207c-0.413-0.426-0.99-0.674-1.581-0.693l-44.859-0.611
                            C53.277,13.502,43.371,5.368,31.413,5.193c-7.084-0.099-13.797,2.581-18.879,7.524c-5.083,4.944-7.947,11.58-8.035,18.654
                            C4.294,46.021,16.028,58.104,30.665,58.295z M56.051,40.631c0.948-2.699,1.498-5.563,1.54-8.525
                            c0.004-1.071-0.171-2.084-0.277-3.126l42.968,0.595l5.753,5.916l-5.406,5.259l-7.552-3.987c-0.717-0.371-1.587-0.333-2.274,0.098
                            l-6.118,3.858l-6.404-2.971c-0.706-0.319-1.517-0.261-2.174,0.161l-4.888,3.135L56.051,40.631z M9.021,31.456
                            c0.082-5.884,2.459-11.396,6.67-15.492s9.776-6.31,15.66-6.228c12.138,0.158,21.877,10.171,21.707,22.316
                            c-0.081,5.883-2.449,11.386-6.66,15.481c-4.21,4.096-9.787,6.32-15.67,6.238C18.592,53.595,8.853,43.583,9.021,31.456z"
                  />
                  <path
                    d="M15.286,31.542c-0.081,5.704,4.509,10.422,10.213,10.5c2.771,0.028,5.389-1.016,7.367-2.94
                            c1.979-1.924,3.094-4.511,3.133-7.272c0.089-5.713-4.499-10.431-10.213-10.5C20.246,21.236,15.357,25.993,15.286,31.542z
                            M19.817,31.595c0.022-1.562,0.637-3.019,1.754-4.105c1.125-1.095,2.6-1.67,4.151-1.639c3.203,0.044,5.788,2.702,5.752,5.915
                        c-0.053,3.112-2.802,5.786-5.914,5.734C22.349,37.465,19.763,34.807,19.817,31.595z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="py-12 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="lg:text-center">
                <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
                  Kávu platíme my
                </h2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Pomáháme firmám i jednotlivcům
                </p>
                <p className="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                  K vašim potřebám přistoupíme individuálně. Je to jednoduché,
                  zavolejte nám nebo pošlete e-mail. Na základě Vašich požadavků
                  zašleme nabídku, nebo si s vámi dáme schůzku u dobré kávy.
                </p>
              </div>

              <div className="mt-10 grid justify-start space-y-8 lg:flex lg:justify-center lg:space-x-8 lg:space-y-0">
                <div className="flex lg:justify-center items-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <PhoneIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-xl  font-medium text-gray-900">
                    800 991 100
                  </p>
                </div>
                <div className="flex lg:justify-center items-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <MailIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <a
                    href="mailto:office@malgen.cz"
                    className="ml-3 text-xl  font-medium text-gray-900"
                  >
                    office@malgen.cz
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default RealityPage
