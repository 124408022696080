import React  from "react"
export const Step = ({step,even,text}) => {
    return( 
       <>
        <div className={"flex relative items-center  py-1 -mt-4 text-gray-600    w-full " }>
            <div className="absolute px-5 inset-0 flex items-center" aria-hidden="true">
                <div className={"w-full border-t border-gray-300 ml-12"  } />
            </div>
            <div className={"relative flex w-full justify-between items-center " }>
                <div className={"  rounded-full px-1 font-bold  text-blue-600 opacity-50 text-2xl "+ (even ? " " : "")}>
                    <div className="p-1  bg-opacity-50   rounded-full w-16 h-16 -ml-2 flex items-center justify-center">
                    <svg className="w-16 h-16" fill="currentColor" stroke="currentColor"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <text x="0" y="20" fill="none" strokeWidth={0.5}  className=" ">{step}</text>
                        
                    </svg>
 
                    </div>
                </div>
                <span className=" ml-5 bg-white px-2 text-right">{text}
                
                </span>
            </div>
        </div>
       </>

    )
}