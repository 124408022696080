import React  from "react"
export const Keys = ({className}) => {
    return( 
        <svg className={className} fill="currentColor"  xmlns="http://www.w3.org/2000/svg"  
        viewBox="0 0 512 512" >
        <path d="M330.447,498.162c-1.913,0.001-3.742-0.79-5.051-2.187l-33.211-35.418c-1.203-1.282-1.873-2.974-1.875-4.732v-16.792
            c0.002-2.027,0.893-3.952,2.436-5.266l11.763-10.046l-10.828-11.492c-2.456-2.605-2.519-6.653-0.146-9.334l11.396-12.876
            l-9.61-10.344c-2.397-2.577-2.479-6.544-0.188-9.216l10.262-11.963l-12.572-10.379c-1.596-1.314-2.52-3.273-2.518-5.341v-41.576
            c-42.73-21.736-59.749-73.998-38.012-116.728c21.736-42.729,73.998-59.748,116.728-38.011s59.748,73.998,38.011,116.728
            c-8.757,17.214-23.004,31.016-40.488,39.222V454.15c0,1.552-0.521,3.058-1.48,4.276l-29.177,37.093
            c-1.251,1.594-3.137,2.56-5.161,2.643H330.447z M304.156,453.085l25.842,27.552l22.722-28.88V297.881
            c-0.001-2.818,1.706-5.355,4.317-6.414c37.424-15.11,55.514-57.698,40.403-95.123s-57.698-55.514-95.122-40.404
            c-37.424,15.11-55.514,57.698-40.403,95.123c7.104,17.595,20.773,31.737,38.117,39.435c2.516,1.098,4.144,3.578,4.151,6.324v42.71
            l15.471,12.779c2.947,2.432,3.365,6.792,0.934,9.74c-0.027,0.033-0.055,0.065-0.083,0.099l-10.828,12.627l9.687,10.42
            c2.423,2.606,2.475,6.625,0.117,9.292l-11.367,12.849l11.485,12.19c2.617,2.784,2.481,7.163-0.303,9.78
            c-0.081,0.077-0.165,0.152-0.251,0.225l-14.875,12.696L304.156,453.085z"/>
        <path d="M359.625,304.758c-3.805,0-6.896-3.072-6.92-6.877v-0.084c0.023-3.82,3.14-6.9,6.961-6.877s6.9,3.139,6.877,6.961
            C366.521,301.686,363.43,304.758,359.625,304.758z"/>
        <path d="M329.61,236.689c-18.686,0-33.833-15.148-33.833-33.833c0-18.686,15.147-33.833,33.833-33.833s33.833,15.147,33.833,33.833
            l0,0C363.425,221.534,348.288,236.67,329.61,236.689z M329.61,182.86c-11.044,0-19.995,8.952-19.995,19.996
            c0,11.043,8.951,19.996,19.995,19.996s19.995-8.953,19.995-19.996l0,0C349.595,191.817,340.648,182.872,329.61,182.86z"/>
        <path d="M112.737,454.919c-3.258,0.002-6.076-2.269-6.767-5.452l-10.282-47.478c-0.375-1.727-0.076-3.531,0.837-5.044l8.704-14.412
            c1.047-1.734,2.802-2.924,4.802-3.252l15.222-2.512l-3.314-15.491c-0.747-3.489,1.282-6.974,4.684-8.047l16.363-5.147l-2.864-13.838
            c-0.714-3.446,1.271-6.879,4.615-7.978l14.917-4.912l-5.397-15.401c-0.683-1.943-0.464-4.089,0.595-5.854l21.393-35.633
            c-25.471-40.638-13.175-94.229,27.462-119.7c34.502-21.625,66.555-12.927,91.038-0.738l0.989,0.609
            c0.858,0.519,1.681,1.024,2.512,1.57c3.187,2.113,4.058,6.41,1.944,9.597s-6.41,4.057-9.597,1.944
            c-0.692-0.443-1.343-0.851-2.075-1.273l-1.018-0.623c-34.552-20.784-79.411-9.623-100.195,24.929
            c-14.879,24.735-13.788,55.913,2.783,79.547c1.571,2.237,1.676,5.19,0.27,7.535l-21.947,36.566l6.663,18.992
            c1.266,3.605-0.632,7.555-4.237,8.819c-0.043,0.016-0.085,0.029-0.128,0.044l-15.754,5.189l2.878,13.906
            c0.718,3.477-1.312,6.933-4.698,7.999l-16.329,5.141l3.522,16.433c0.815,3.733-1.551,7.42-5.285,8.234
            c-0.118,0.026-0.236,0.049-0.354,0.068l-19.262,3.176l-5.632,9.326l7.998,36.898l34.367-13.007l79.27-131.861
            c1.442-2.424,4.218-3.721,7.002-3.272c14.868,2.376,30.108,0.049,43.588-6.656c3.41-1.726,7.572-0.36,9.299,3.048
            c1.726,3.41,0.36,7.572-3.049,9.299c-0.03,0.016-0.061,0.03-0.092,0.045c-14.607,7.268-30.98,10.229-47.208,8.538l-78.184,130.076
            c-0.797,1.325-2.015,2.347-3.459,2.898l-44.122,16.702C114.419,454.772,113.581,454.925,112.737,454.919L112.737,454.919z"/>
        <path d="M237.388,304.26c-3.821-0.019-6.904-3.131-6.886-6.952c0.006-1.241,0.346-2.458,0.984-3.523l0.048-0.076
            c1.987-3.265,6.245-4.302,9.51-2.314c3.265,1.987,4.301,6.245,2.314,9.51C242.089,302.986,239.827,304.257,237.388,304.26z"/>
        <path d="M402.468,105.171c-5.93-56.134-56.244-96.833-112.379-90.903c-38.813,4.101-70.517,27.771-84.428,60.543
            c4.789-0.288,6.836,0.005,15.819-0.439c9.878-17.897,26.945-32.944,47.833-40.903c45.57-17.365,96.589,5.5,113.953,51.069
            c17.366,45.57-5.499,96.589-51.068,113.954c-2.529,0.639-4.484,2.646-5.056,5.191c-0.838,3.729,1.505,7.43,5.233,8.268
            c1.327,0.327,2.72,0.251,4.004-0.218C380.17,195.486,407.374,151.62,402.468,105.171z"/>
        </svg>

    )
}