import React  from "react"
export const Katalog = ({className}) => {
    return( 
        <svg className={className} fill="currentColor"  xmlns="http://www.w3.org/2000/svg"   
         viewBox="0 0 136.06 136.06"  >
   
       <path d="M128.823,110.955c-3.034-4.276-3.513-6.415-4.098-8.9c-0.598-2.664-1.278-5.687-4.827-11.386l-5.065-7.526
           c-2.712-3.788-5.759-7.085-10.394-6.058c-1.84,0.395-3.394,0.908-3.537,0.956c-0.012,0.012-0.012,0.012-0.023,0.012l-7.766,2.676
           c-0.036,0.013-0.06,0.024-0.096,0.036h-0.012c-0.036,0-0.036,0-0.084,0.036c-1.84,0.813-4.636,1.804-6.308,2.354L74.846,65.233
           c-2.031-3.226-6.643-4.265-9.868-2.222c-3.345,2.103-4.337,6.523-2.234,9.892l16.631,25.328c-0.979-0.37-1.995-0.74-2.987-1.039
           c-0.788-0.239-1.625-0.358-2.473-0.358c-3.357,0-6.069,1.815-7.049,4.73c-1.314,3.883,1.254,7.993,6.248,10l1.219,0.479
           c4.791,1.852,16.033,6.2,28.53,16.666c0.358,0.299,0.8,0.454,1.23,0.454c0.347,0,0.705-0.096,1.027-0.299
           c4.91-3.082,8.459-5.472,11.959-7.837c3.381-2.271,6.69-4.517,11.207-7.348c0.441-0.275,0.74-0.729,0.848-1.243
           C129.229,111.923,129.122,111.386,128.823,110.955z M114.94,117.861c-3.189,2.15-6.403,4.324-10.692,7.024
           c-12.557-10.238-23.703-14.54-28.542-16.415l-1.159-0.442c-2.473-0.991-4.791-3.07-4.05-5.245c0.657-1.971,2.783-2.126,3.417-2.126
           c0.466,0,0.932,0.06,1.362,0.203c3.835,1.146,8.303,3.345,8.351,3.369c0.776,0.37,1.696,0.19,2.271-0.454
           c0.573-0.646,0.645-1.589,0.167-2.318l-20.096-30.62c-0.979-1.565-0.514-3.608,1.04-4.588c1.518-0.956,3.644-0.49,4.624,1.063
           l12.593,19.175l4.743,7.204c0.358,0.573,0.979,0.872,1.601,0.872c0.358,0,0.705-0.107,1.039-0.311
           c0.885-0.585,1.136-1.768,0.55-2.652l-3.369-5.137c1.242-0.442,2.808-0.992,4.206-1.554l3.822,5.83
           c0.574,0.896,1.769,1.136,2.641,0.562c0.562-0.37,0.872-0.979,0.872-1.601c0-0.358-0.107-0.729-0.322-1.039l-3.333-5.113
           l4.05-1.398l3.596,5.484c0.574,0.884,1.757,1.123,2.641,0.537c0.562-0.37,0.86-0.979,0.86-1.589c0-0.358-0.096-0.729-0.311-1.052
           l-2.975-4.552c0.227-0.06,0.478-0.107,0.717-0.155c2.233-0.502,3.5,0.395,6.439,4.492l5.018,7.419
           c3.154,5.114,3.739,7.683,4.301,10.18c0.562,2.425,1.063,4.73,3.561,8.59C120.854,113.883,117.916,115.854,114.94,117.861z"/>
       
           <path d="M64.619,94.767H16.388c-3.154,0-5.734-2.58-5.734-5.734V16.393c0-3.166,2.581-5.735,5.734-5.735h103.225
               c3.154,0,5.734,2.569,5.734,5.735v72.208c1.123,1.876,1.983,3.513,2.641,4.946c0.729-1.361,1.183-2.867,1.183-4.516V16.393
               c0-5.281-4.289-9.558-9.558-9.558H16.388c-5.269,0-9.558,4.277-9.558,9.558v72.639c0,5.269,4.289,9.558,9.558,9.558h45.567
               C62.6,97.133,63.508,95.842,64.619,94.767z"/>
           <path d="M20.211,85.209V20.216h82.197v51.445c0.263-0.048,0.514-0.12,0.788-0.168
               c0.98-0.215,1.96-0.334,2.928-0.334c0.035,0,0.071,0.012,0.107,0.012V16.393H16.388v72.639h50.083l-2.509-3.823H20.211z"/>
           <path d="M115.789,48.889c-2.114,0-3.823,1.721-3.823,3.823c0,2.115,1.709,3.823,3.823,3.823
               s3.823-1.708,3.823-3.823C119.612,50.61,117.903,48.889,115.789,48.889z"/>
           
               <path d="M59.792,73.357H44.082l0.012-20.155c0-1.063-0.849-1.911-1.912-1.923h-1.876l-0.024-3.345L61.25,32.068
                   c0.036,0,0.37,0.071,0.394,0.071l20.693,15.794v3.333l-1.888,0.012c-1.063,0-1.912,0.848-1.912,1.912v12.437l3.823,5.831V55.09
                   h1.899c1.052,0,1.9-0.872,1.9-1.912V46.99c0-0.597-0.275-1.159-0.741-1.517L63.95,29.069c-1.397-1.016-3.632-1.195-5.316,0.036
                   L37.212,45.473c-0.478,0.37-0.752,0.932-0.752,1.529l0.036,6.188c0,1.051,0.86,1.899,1.899,1.899l1.876,0.012l-0.012,18.936
                   c0,1.732,1.422,3.143,3.142,3.143h18.722l-1.768-2.7C60.127,74.122,59.972,73.728,59.792,73.357z"/>
               <path d="M57.678,62.223v-7.264h7.264v4.958c1.183-0.49,2.425-0.824,3.751-0.824c0.024,0,0.048,0.012,0.072,0.012
                   v-4.671c0-1.816-1.47-3.298-3.297-3.298h-8.315c-1.828,0-3.297,1.481-3.297,3.298v8.315c0,1.816,1.47,3.31,3.297,3.31h2.15
                   c0.43-1.422,1.171-2.724,2.222-3.835H57.678z"/>
          
  
   </svg>
   

    )
}